import React, { Component } from 'react'

export default class Projects extends Component {
  render() {
    return (
      <div>
				<section className="colorlib-work" data-section="projects">
					<div className="colorlib-narrow-content">
						<div className="row">
							<div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
								<span className="heading-meta">My Public Work</span>
								<h2 className="colorlib-heading animate-box">Open Source Software</h2>
							</div>
						</div>
						<div className="row">
							<div className="col-md-4 animate-box" data-animate-effect="fadeInLeft">
								<div className="project">
									<div className="desc">
										<div className="con">
											<h3><a href="https://github.com/numascale/numascope">NUMAscope</a></h3>
											<span>Realtime collection and live graphing of on-chip cache coherent events, using Golang and JavaScript</span>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 animate-box" data-animate-effect="fadeInRight">
								<div className="project">
									<div className="desc">
										<div className="con">
											<h3><a href="https://github.com/numascale/firmware">NumaConnect2 firmware</a></h3>
											<span>Firmware to boot servers as one large cache-coherent system, gen 2 interconnect</span>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 animate-box" data-animate-effect="fadeInTop">
								<div className="project">
									<div className="desc">
										<div className="con">
											<h3><a href="https://github.com/numascale/nc-utils/tree/master/numaplace">NUMAplace</a></h3>
											<span>Workload dynamic placement tool</span>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 animate-box" data-animate-effect="fadeInBottom">
								<div className="project">
									<div className="desc">
										<div className="con">
											<h3><a href="https://github.com/numascale/legacy-firmware">NumaConnect firmware</a></h3>
											<span>Firmware to boot servers as one large cache-coherent system, gen 1 interconnect</span>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 animate-box" data-animate-effect="fadeInLeft">
								<div className="project">
									<div className="desc">
										<div className="con">
											<h3><a href="https://github.com/dblueman/autorobo">AutoRobo</a></h3>
											<span>Modular robotics platform</span>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 animate-box" data-animate-effect="fadeInLeft">
								<div className="project">
									<div className="desc">
										<div className="con">
											<h3><a href="https://github.com/dblueman/">Opensource Golang libraries</a></h3>
											<span>Various useful Golang libraries, including NASA JPL Mars 2020 Helicopter and Arctic Code Vault Contributions (Linux Kernel)</span>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 animate-box" data-animate-effect="fadeInLeft">
								<div className="project">
									<div className="desc">
										<div className="con">
											<h3><a href='https://www.google.com/search?q="Signed-off-by:+Daniel+J+Blueman"'>Linux Kernel patches</a></h3>
											<span>Various kernel contributions</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
      </div>
    )
  }
}
