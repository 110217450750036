import React, { Component } from 'react'

export default class Presentations extends Component {
  render() {
    return (
      <div>
				<section className="colorlib-work" data-section="presentations">
					<div className="colorlib-narrow-content">
						<div className="row">
							<div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
								<span className="heading-meta">My Public Work</span>
								<h2 className="colorlib-heading animate-box">Public Presentations, Research and Volunteering</h2>
							</div>
						</div>
						<div className="row">
							<div className="col-md-4 animate-box" data-animate-effect="fadeInLeft">
								<div className="project">
									<div className="desc">
										<div className="con">
											<h3><a href="pres/py2go.pdf">Golang and Javascript: The Future of Cloud</a></h3>
											<span>This talk addresses cloud application design choices and navigates the viewer through the benefits and pitfalls</span>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 animate-box" data-animate-effect="fadeInRight">
								<div className="project">
									<div className="desc">
										<div className="con">
											<h3><a href="pres/great-wifi/">Making WiFi Great</a></h3>
											<span>This talk gives the viewer an overview of what makes WiFi work poor, and offers practical advice on setup and tuning to achieve great WiFi</span>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 animate-box" data-animate-effect="fadeInRight">
								<div className="project">
									<div className="desc">
										<div className="con">
											<h3><a href="pres/scaling-x86.pdf">Breaking the Limits of x86</a></h3>
											<span>This talk introduces large-scale (hundreds of cores) computing systems, Linux kernel changes needed and challenges</span>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 animate-box" data-animate-effect="fadeInTop">
								<div className="project">
									<div className="desc">
										<div className="con">
											<h3><a href="https://drive.google.com/file/d/18Ni1VOFU4eTNV1UU6gfXiisvDrK4hWB9/view?usp=drive_link">Internet For All - Singapore HDB rental community</a></h3>
											<span>Charity collaboration to deliver WiFi to marginalised community in Singapore</span>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 animate-box" data-animate-effect="fadeInTop">
								<div className="project">
									<div className="desc">
										<div className="con">
											<h3><a href="https://drive.google.com/file/d/1sed7TQIY50q0QJX83Rm0AkACSCb2Khnh/view?usp=drive_link">Interactive Learning Platform - Singapore PAP Community Foundation</a></h3>
											<span>Development of a new eLearning paradigm, and evaluation in a Singapore school</span>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 animate-box" data-animate-effect="fadeInTop">
								<div className="project">
									<div className="desc">
										<div className="con">
											<h3><a href="https://drive.google.com/file/d/1Hfl__nRToqut4TsyuWSWc4J5D2R35Tr0/view?usp=drive_link">Mount Agung Evac Center WiFi</a></h3>
											<span>Proposal to deploy WiFi in the Mount Agung evacuation centre after volcanic events</span>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 animate-box" data-animate-effect="fadeInLeft">
								<div className="project">
									<div className="desc">
										<div className="con">
											<h3><a href="https://arxiv.org/abs/2110.04030">Chromatic Aberration Recovery on Arbitrary Images</a></h3>
											<span>MSc by Research Thesis</span>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 animate-box" data-animate-effect="fadeInLeft">
								<div className="project">
									<div className="desc">
										<div className="con">
											<h3><a href="https://arxiv.org/abs/2111.11836">NUMAscope research paper</a></h3>
											<span>Capturing and Visualizing Hardware Metrics on Large ccNUMA Systems</span>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 animate-box" data-animate-effect="fadeInTop">
								<div className="project">
									<div className="desc">
										<div className="con">
                                 <h3><a href="https://open-school.net">Open School</a></h3>
                                 <span>Initiave to help schools deploy sustainable and robust computing resources</span>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 animate-box" data-animate-effect="fadeInLeft">
								<div className="project">
									<div className="desc">
										<div className="con">
                                 <h3><a href="https://summit.fossasia.org">FOSSASIA OpenTech Summit</a></h3>
                                 <span>Organising and running an annual OpenTech summit in South East Asia</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
      </div>
    )
  }
}
